const React = require('react');
const { useContext } = require('react');
const { bool, string, shape, func } = require('prop-types');
const { loadable } = require('nordic/lazy');

const { trackEvent } = require('../../../lib/tracking');
const withTooltip = require('../../tooltip/with-tooltip');
const withCardTooltip = require('../../card-tooltip/with-card-tooltip');
const { ACTION_TYPES, BUTTON_TYPES } = require('./utils/action-types');
const StaticPropsContext = require('../../context/static-props');
const { getFormattedTrackingData, dispatchAddToCardShopsInfo } = require('../../../utils/mercadoShopsTracking');
const { scrollToAndFocusQAI } = require('../../../utils/scrollToAndFocusElement');

const AdditionalInfo = loadable(() => import('../../additional-info/index'));
const BuyProtected = loadable(() => import('../../buy-protected'));
const BuildButtonAction = loadable(() => import('./builders/build-button-action'));
const BuildButtonActionParams = loadable(() => import('./builders/build-button-action-params'));
const BuildContactInfoAction = loadable(() => import('./builders/build-contact-info-action'));
const BuildMShopsModalAction = loadable(() => import('./build-mshops-modal-action'));
const BuildUnitsLTRModal = loadable(() => import('./build-units-ltr-modal'));
const BuildRequestModalQuestions = loadable(() => import('./builders/build-request-modal-questions'));
const BuildRequestModalUnregisteredQuestions = loadable(() =>
  import('./builders/build-request-modal-unregistered-questions'),
);
const BuildQuoteModal = loadable(() => import('./build-quote-modal'));
const BuildQuotationModal = loadable(() => import('./build-quotation-modal'));
const BuildUnregisteredQuotationModal = loadable(() => import('./build-unregistered-quotation-modal'));
const BuildWhatsAppOpsButtonAction = loadable(() => import('./builders/build-whatsapp-ops-action'));
const BuildScrollToAction = loadable(() => import('./builders/build-scroll-to-action'));
const Loyalty = loadable(() => import('../../loyalty/index'));

const namespace = 'ui-pdp-action';

const actionTypesWithLoader = [
  ACTION_TYPES.ADD_TO_CART,
  ACTION_TYPES.BUY_NOW_WITH_ONE_PAY_FOR_ALL,
  ACTION_TYPES.BOTTOMSHEET_MODAL,
  ACTION_TYPES.SEQUENCER,
];

const trackAddToCartShops = responseShowAddToCartModal => {
  if (responseShowAddToCartModal.urlAddToCartServiceResponse && responseShowAddToCartModal.shopsDefaultTrackingData) {
    const urlTarget = new URL(responseShowAddToCartModal.urlAddToCartServiceResponse);
    const path = urlTarget.pathname;
    const setQuantitySelected = responseShowAddToCartModal.quantitySelected || 0;

    if (!path.includes('error')) {
      responseShowAddToCartModal.shopsDefaultTrackingData.quantity = setQuantitySelected;

      const shopsTrackingData = getFormattedTrackingData(
        responseShowAddToCartModal.shopsDefaultTrackingData,
        responseShowAddToCartModal.shopsDefaultTrackingData.variation_id,
      );

      dispatchAddToCardShopsInfo(window.tracking_mediator, shopsTrackingData);
    }
  }
};

const buildAction = (actionProps, onClickHandler) => {
  const { actionType } = actionProps;

  const buttonProps = {};
  buttonProps['aria-disabled'] = actionProps.ariaDisabled;
  buttonProps.tabIndex = actionProps.tabIndex;
  buttonProps.form = actionProps.form;
  const action = {
    [ACTION_TYPES.BUY_NOW_WITH_CREDITS]: () => {
      buttonProps.href = actionProps.target;
      buttonProps.target = actionProps.html_target;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.WHATSAPP_LINK]: () => {
      buttonProps.href = actionProps.target;
      buttonProps.target = actionProps.html_target;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.CALL_LINK]: () => {
      buttonProps.href = actionProps.target;
      buttonProps.target = actionProps.html_target;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.LINK]: () => {
      buttonProps.href = actionProps.target;
      buttonProps.target = actionProps.html_target;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.SCHEDULE_VISIT_MOTORS_LINK]: () => {
      buttonProps.href = actionProps.target;
      buttonProps.target = actionProps.html_target;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.CALL]: () => (
      <BuildContactInfoAction contactType="phones" onClickHandler={onClickHandler} {...actionProps} />
    ),
    [ACTION_TYPES.WHATSAPP]: () => (
      <BuildContactInfoAction contactType="whatsapp" onClickHandler={onClickHandler} {...actionProps} />
    ),
    [ACTION_TYPES.WHATSAPP_INTERVENTION]: () => (
      <BuildContactInfoAction contactType="whatsapp-intervention" onClickHandler={onClickHandler} {...actionProps} />
    ),
    [ACTION_TYPES.MODAL_QUESTIONS]: () => <BuildRequestModalQuestions {...actionProps} />,
    [ACTION_TYPES.SCHEDULE_VISIT_MOTORS]: () => (
      <BuildRequestModalQuestions actionType="schedule_visit_motors" {...actionProps} />
    ),
    [ACTION_TYPES.MODAL_UNREGISTERED_QUESTION]: () => <BuildRequestModalUnregisteredQuestions {...actionProps} />,
    [ACTION_TYPES.SCROLL_TO]: () => <BuildScrollToAction onClickHandler={onClickHandler} {...actionProps} />,
    // Validar si esto se sigue usando, si no se usa se puede borrar
    [ACTION_TYPES.MSHOPS_BUY_NOW]: () => <BuildMShopsModalAction onClickHandler={onClickHandler} {...actionProps} />,
    [ACTION_TYPES.BUY_NOW_SHOPS]: () => {
      buttonProps.formAction = actionProps.target;
      buttonProps.type = BUTTON_TYPES.SUBMIT;
      return (
        <BuildButtonActionParams buttonProps={buttonProps} onClickHandler={onClickHandler} actionProps={actionProps} />
      );
    },
    [ACTION_TYPES.MODAL_QUOTE]: () => <BuildQuoteModal onClickHandler={onClickHandler} {...actionProps} />,
    [ACTION_TYPES.MODAL_QUOTATION]: () => <BuildQuotationModal {...actionProps} />,
    [ACTION_TYPES.MODAL_UNREGISTERED_QUOTATION]: () => <BuildUnregisteredQuotationModal {...actionProps} />,
    [ACTION_TYPES.MODAL_CHECK_UNITS]: () => <BuildUnitsLTRModal {...actionProps} />,
    [ACTION_TYPES.ADDITIONAL_INFO]: () => <AdditionalInfo {...actionProps.featuredInfo} />,
    [ACTION_TYPES.ADD_TO_CART]: () => {
      buttonProps.type = BUTTON_TYPES.SUBMIT;
      buttonProps.formAction = actionProps.submit_target;
      actionProps.isLoading = actionProps.loading;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.SEQUENCER]: () => {
      buttonProps.type = BUTTON_TYPES.SUBMIT;
      buttonProps.formAction = actionProps.submit_target;
      actionProps.isLoading = actionProps.isLoadingButton;

      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.BUY_NOW_WITH_ONE_PAY_FOR_ALL]: () => {
      actionProps.isLoading = actionProps.isLoadingButton;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    [ACTION_TYPES.LOYALTY]: () => <Loyalty {...actionProps} />,
    [ACTION_TYPES.BUY_PROTECTED]: () => <BuyProtected {...actionProps} />,
    [ACTION_TYPES.WHATSAPP_OPS_LINK]: () => {
      buttonProps.href = actionProps.target;
      buttonProps.target = actionProps.html_target;
      return (
        <BuildWhatsAppOpsButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />
      );
    },
    [ACTION_TYPES.ASK_QUESTIONS_AI]: () => {
      actionProps.called_from = 'main_actions';
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
    default: () => {
      buttonProps.type = BUTTON_TYPES.SUBMIT;
      buttonProps.formAction = actionProps.target;
      return <BuildButtonAction buttonProps={buttonProps} onClickHandler={onClickHandler} {...actionProps} />;
    },
  };
  return (action[actionType] || action.default)();
};

const Action = ({
  onClick,
  showQuantityError,
  showVariationsError,
  showAddToCartModal,
  showQuestionsAiModal,
  triggerBuyNowOnePayForAll,
  triggerSequencer,
  saveFrontendStatsd,
  should_raise_errors: shouldRaiseErrors,
  deviceType,
  redirectToLogin,
  isFetchingData,
  ...props
}) => {
  const { vertical } = useContext(StaticPropsContext);
  const isButtonWithLoader = actionTypesWithLoader.includes(props.actionType);
  const actionKey = props?.label?.text;
  const shouldSaveStats =
    props.actionType === ACTION_TYPES.SEQUENCER || props.actionType === ACTION_TYPES.ASK_QUESTIONS_AI;

  if (shouldSaveStats && saveFrontendStatsd) {
    props.saveFrontendStatsd = saveFrontendStatsd;
  }

  const triggerModalAddToCart = async () => {
    const responseShowAddToCartModal = await showAddToCartModal({
      itemId: props.itemId,
      labelText: actionKey,
    });
    if (responseShowAddToCartModal) {
      trackAddToCartShops(responseShowAddToCartModal);
    }
  };

  const onClickHandler = async (e, component, focus) => {
    if (props.is_login_required && redirectToLogin) {
      e.preventDefault();
      redirectToLogin(props.itemId, props.featureName, props.loginType);
    }

    if (props.disabled) {
      e.preventDefault();
      if (shouldRaiseErrors) {
        showVariationsError(deviceType, props.label.text);
        showQuantityError();
      }
      return;
    }

    if (isButtonWithLoader) {
      e.preventDefault();
      if (props.isLoadingButton || props.loading) {
        return;
      }
    }

    switch (props.actionType) {
      case ACTION_TYPES.SEQUENCER:
        triggerSequencer(actionKey);
        break;
      case ACTION_TYPES.ADD_TO_CART:
        await triggerModalAddToCart();
        break;
      case ACTION_TYPES.BUY_NOW_WITH_ONE_PAY_FOR_ALL:
        triggerBuyNowOnePayForAll(actionKey);
        break;
      case ACTION_TYPES.ASK_QUESTIONS_AI:
        e.preventDefault();
        if (deviceType === 'desktop') {
          scrollToAndFocusQAI('#questions');
        } else {
          const { target, disabled } = props;

          showQuestionsAiModal({ target, disabled, called_from: 'main_actions', source: 'vpp' });
        }

        break;
      default:
        break;
    }

    trackEvent(props.track);

    if (onClick) {
      onClick(e, component, focus);
    }

    if (vertical === 'real-estate') {
      const propagateHash = props.actionType === ACTION_TYPES.LINK && window.location.hash && props.target;
      if (propagateHash) {
        e.preventDefault();
        window.location = props.target + window.location.hash;
      }
    }
  };

  return buildAction({ ...props, deviceType }, onClickHandler);
};

Action.propTypes = {
  disabled: bool,
  track: shape({}),
  type: string.isRequired,
  onClick: func,
  deviceType: string,
  should_raise_errors: bool,
  showVariationsError: func,
  updateBudgetComponents: func,
  triggerBuyNowOnePayForAll: func,
  triggerSequencer: func,
  saveFrontendStatsd: func,
  tabIndex: string,
  form: string,
  target: string.isRequired,
  html_target: string,
  featuredInfo: shape({}),
};

Action.defaultProps = {
  disabled: false,
  track: null,
  deviceType: null,
  should_raise_errors: false,
  showVariationsError: null,
  updateBudgetComponents: null,
  onClick: null,
  tabIndex: null,
  form: null,
  triggerBuyNowOnePayForAll: null,
  triggerSequencer: null,
  saveFrontendStatsd: null,
  target: null,
  html_target: null,
  featuredInfo: null,
};

module.exports = React.memo(
  withCardTooltip(withTooltip(Action, `${namespace}__tooltip`), `${namespace}__card-tooltip`),
);
